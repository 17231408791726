import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ApiService,
  EventEmitterService,
  MatDialogUtilsService,
  UtilsService,
} from "src/app/shared/services";
import Chart from "chart.js";
import { FormControl, Validators } from "@angular/forms";
import { EnvService } from "src/app/shared/services/env.service";
import { CookieService } from "ngx-cookie-service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { ChatService } from "src/app/shared/services/api/chat.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { AuthService, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: "app-chat-products",
  templateUrl: "./chat-products.component.html",
  styleUrls: ["./chat-products.component.less"],
})
export class ChatProductsComponent implements OnInit, OnChanges {
  deviceWidth: number;
  @Input() quizId: string = "";
  @Input() productsData: any[] = [];
  @Input() subStylesList: any[] = [];
  @Input() conversation_id: string = "";
  @Input() chatData: any;
  isHandset: boolean;
  userId: any;
  @Output() msgFromGenome = new EventEmitter();
  @Output() showLoader = new EventEmitter();
  @Output() chat = new EventEmitter();
  @Output() plpLoadingStatus = new EventEmitter();
  deviceHeight: number;
  brandList: any[] = [];

  pinBoardList: any[] = [];
  allItems = false;
  departmentList: any;
  categoryId: string;
  categoryName: string;
  loaded = false;
  lastScrollPosition: number;
  incomingProductData: any[] = [];
  public Math: Math = Math;
  loadMoreBtn = true;
  errorMessage: string;
  @Input() noProducts: boolean = false;
  @Input() pCounter = 1;
  @Input() plpLoading = false;
  @ViewChild("signupDialog") signupDialog: TemplateRef<any>;
  emailAvailable: boolean;
  page: number = 1;
  error: boolean;
  errorMsg: string;
  btnLoader: boolean = false;
  isAPICall: boolean;
  plpPageNo: number = 0;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private router: Router,
    private snackbar: MatSnackBar,
    private matDialogUtils: MatDialogUtilsService,
    private utils: UtilsService,
    private eventEmitterService: EventEmitterService,
    private breakpointObserver: BreakpointObserver,
    public chatService: ChatService,
    private eventService: EventEmitterService,
    private socialAuthService: AuthService,
    private cookie: CookieService
  ) {
    this.getBrands();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (sessionStorage.getItem("promptCategory") && sessionStorage.getItem('promptCategory')!=='null') {
      this.chatService.promptCategory =
        sessionStorage.getItem("promptCategory");
    }
    if (
      this.chatService.promptCategory &&
      changes.plpLoading &&
      changes.plpLoading.currentValue
    ) {
      this.plpPageNo++;
      this.getProductsPLP();
    } else {
      if (changes.chatData && changes.chatData.currentValue) {
        this.incomingProductData = this.chatData.products;
        this.allItems = false;
        if (this.incomingProductData?.length > 0) {
          if (
            this.incomingProductData.some(
              (f) =>
                f.rationale.startsWith("Yes.") || f.rationale.startsWith("Yes,")
            )
          ) {
            this.loadMoreBtn = true;
          } else {
            this.loadMoreBtn = false;
          }
          if (
            this.incomingProductData.some(
              (f) =>
                f.rationale.startsWith("Yes.") || f.rationale.startsWith("Yes,")
            )
          ) {
            this.chatService.viewAllBtn = false;
          } else {
            this.chatService.viewAllBtn = true;
          }
        }
      }
      if (changes.productsData && changes.productsData.currentValue) {
        this.loadProducts();
        this.allItems = false;
      }

      if (
        changes.noProducts &&
        changes.noProducts.currentValue &&
        this.noProducts
      ) {
        this.chatService.viewAllBtn = true;
        this.loaded = true;
        this.loadMoreBtn = false;
      }
    }
  }

  ngOnInit() {
    this.deviceHeight = window.innerHeight;
    this.deviceWidth = window.innerWidth;
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isHandset = result.matches;
      });
    document.body.style.setProperty("background-color", "white", "important");

    this.userId = JSON.parse(localStorage.getItem("user")).id;
    if (sessionStorage.getItem("promptCategory") && sessionStorage.getItem('promptCategory')!=='null') {
      this.chatService.promptCategory =
        sessionStorage.getItem("promptCategory");
    }

    if (this.chatService.promptCategory) {
      // this.allItems = true;
      setTimeout(() => {
        this.getProductsPLP();
      }, 500);
    }
    this.chatService.tabObs.subscribe(res=>{
      this.allItems = res;
    })
  }

  getProductsPLP() {
    // this.chatService.promptCategory = this.chatService.promptCategory.repl
    this.api
      .getProducts(this.chatService.promptCategory, "", "", "", this.plpPageNo)
      .subscribe((resp: any) => {
        this.plpLoading = false;
        if (resp && resp.products) {
          this.plpLoadingStatus.emit(false);
          if (
            this.chatService.productsDataPLP && this.chatService.productsDataPLP[0] &&
            this.chatService.productsDataPLP[0].name == "" &&
            this.chatService.productsDataPLP[0].main_image == "" &&
            this.chatService.productsDataPLP[0].product_id == ""
          ) {
            this.chatService.productsDataPLP = [];
          }
          resp.products.forEach((f) => {
            this.chatService.productsDataPLP.push({
              main_image: f.main_image,
              is_new: f.is_new,
              product_id: f.sku,
              name: f.name,
              min_price: f.min_price,
              max_price: f.max_price,
              min_was_price: f.min_was_price,
              max_was_price: f.max_was_price,
              percent_discount: f.percent_discount,
              sku: f.sku,
              product_status: f.product_status,
              url: f.product_detail_url,
              style_match: false,
              brand_name: f.brand_name,
              condition: f.condition,
            });
          });
        }
      });
  }

  get array(): any[] {
    return this.allItems ? this.chatService.productsDataPLP : this.productsData;
  }

  get isLoggedIn(): boolean {
    if (JSON.parse(localStorage.getItem("user"))) {
      return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).email &&
        JSON.parse(localStorage.getItem("user")).email.length > 0
      );
    } else {
      return false;
    }
  }

  getAllDepartments() {
    this.api.getAllDepartments().subscribe((resp: any) => {
      this.departmentList = resp.all_departments;

      this.categoryName = this.findCategoryName(
        this.departmentList,
        this.categoryId
      );
      // this.loaded = true;
    });
  }

  findCategoryName(data: LivingRoom[], inputCategoryId: string): string | null {
    for (const livingRoom of data) {
      for (const department of livingRoom.departments) {
        for (const category of department.categories) {
          for (const subCategory of category.sub_categories) {
            if (subCategory.category_id === inputCategoryId) {
              return subCategory.sub_category
                ? subCategory.sub_category
                : category.category_name;
            }
          }
        }
      }
    }
    return null;
  }

  goToPLP() {
    this.router.navigate([]).then(() => {
      window.open(`/products/all?filters=category:${this.categoryId}`);
    });
  }

  getBrands() {
    this.api.getAllBrandNames().subscribe((res) => {
      this.brandList = res;
    });
  }
  loadProducts() {
    if (this.productsData) {
      this.allItems=false;
      if (
        !this.productsData.every(
          (f) => f.rationale.startsWith("No.") || f.rationale.startsWith("No,")
        )
      ) {
        this.loadMoreBtn = true;
      } else {
        this.loadMoreBtn = false;
      }
      if (
        this.productsData.every(
          (f) => f.rationale.startsWith("No.") || f.rationale.startsWith("No,")
        )
      ) {
        this.chatService.viewAllBtn = true;
      } else {
        this.chatService.viewAllBtn = false;
      }

      this.productsData.forEach((e) => {
        e["pinned"] = false;
        e["display"] = true;
        if (e.rationale) {
          e.llmExpanded = this.isHandset ? false : true;
          if (e.rationale.startsWith("No.") || e.rationale.startsWith("No,")) {
            e["display"] = false;
          }

          if (
            e.rationale.startsWith("Yes.") ||
            e.rationale.startsWith("Yes,")
          ) {
            e["display"] = true;
            e.rationale = e.rationale.startsWith("Yes.")
              ? e.rationale.replace("Yes. ", "").trim()
              : e.rationale.replace("Yes, ", "").trim();
            e.rationale =
              e.rationale.charAt(0).toUpperCase() + e.rationale.slice(1);
          }
        }
      });
      this.productsData = this.productsData.filter((f) => f.display);

      if (this.noProducts) {
        this.chatService.viewAllBtn = true;
        this.loaded = true;
        this.loadMoreBtn = false;
      }

      if (!this.chatService.viewAllBtn) {
        this.loadMoreByCounter();
      }

      this.categoryId = this.findMaxCategoryId(this.productsData);
      this.getAllDepartments();
      this.productsData.forEach((f, index) => {
        this.getProductBySku(f, index);
      });
    }

    this.deviceHeight = window.innerHeight;
    this.deviceWidth = window.innerWidth;

    this.hideIntercomIcon();
  }

  loadMoreByCounter() {
    if (this.productsData.length < 20 && this.pCounter === 1) {
      this.loadMore();
    } else if (this.productsData.length >= 20 && this.pCounter === 1) {
      this.loadMoreBtn = true;
      this.loaded = true;
    } else if (this.productsData.length < 40 && this.pCounter === 2) {
      this.loadMore();
    } else if (this.productsData.length >= 40 && this.pCounter === 2) {
      this.loadMoreBtn = true;
      this.loaded = true;
    } else if (this.productsData.length < 60 && this.pCounter === 3) {
      this.loadMore();
    } else if (this.productsData.length >= 60 && this.pCounter === 3) {
      this.loadMoreBtn = true;
      this.loaded = true;
    } else if (this.productsData.length < 80 && this.pCounter === 4) {
      this.loadMore();
    } else if (this.productsData.length >= 80 && this.pCounter === 4) {
      this.loadMoreBtn = true;
      this.loaded = true;
    } else if (this.productsData.length < 100 && this.pCounter === 5) {
      this.loadMore();
    } else if (this.productsData.length >= 100 && this.pCounter === 5) {
      this.loadMoreBtn = true;
      this.loaded = true;
    } else if (this.productsData.length < 120 && this.pCounter === 6) {
      this.loadMore();
    } else if (this.productsData.length >= 120 && this.pCounter === 6) {
      this.loadMoreBtn = true;
      this.loaded = true;
    } else if (this.productsData.length < 140 && this.pCounter === 7) {
      this.loadMore();
    } else if (this.productsData.length >= 140 && this.pCounter === 7) {
      this.loadMoreBtn = true;
      this.loaded = true;
    }
  }

  get getInnerBodyDeskWidth() {
    return document.getElementById("innerbodyTxt")?.clientWidth;
  }

  findMaxCategoryId(products: any[]): string | null {
    const categoryCount: { [key: string]: number } = {};

    products.forEach((product) => {
      if (categoryCount[product.category_id]) {
        categoryCount[product.category_id]++;
      } else {
        categoryCount[product.category_id] = 1;
      }
    });

    let maxCategoryId: string | null = null;
    let maxCount = 0;

    for (const categoryId in categoryCount) {
      if (categoryCount[categoryId] > maxCount) {
        maxCount = categoryCount[categoryId];
        maxCategoryId = categoryId;
      }
    }

    return maxCategoryId;
  }

  getProductBySku(f: any, index) {
    this.chatService.getProductBySku(f.product_id).then((resp) => {
      if (resp && resp[0]) {
        console.log(this.subStylesList, "SUBSTYLE");
        f["main_image"] = resp[0].main_product_images;
        f["is_new"] = resp[0].is_new;
        f["name"] = resp[0].product_name;
        f["min_price"] = resp[0].min_price;
        f["max_price"] = resp[0].max_price;
        f["min_was_price"] = resp[0].min_was_price;
        f["max_was_price"] = resp[0].max_was_price;
        f["condition"] = resp[0].product_condition;
        f["percent_discount"] = resp[0].percent_discount;
        f["sku"] = f.product_id;
        f["product_status"] = resp[0].product_status;
        f["style_match"] =
          this.subStylesList && this.subStylesList.length > 0
            ? this.subStylesList
                .map((m) => m.style_name)
                .includes(resp[0].style)
              ? true
              : false
            : false;

        const brandName = this.brandList.find(
          (f) => f.value.toLowerCase() === resp[0].brand.toLowerCase()
        );
        f["brand_name"] = brandName ? brandName.name : resp[0].brand;
      }
      // if (index === this.productsData.length - 1) {

      //   setTimeout(() => {
      //     if (document.getElementById("fullcontainer")) {
      //       document
      //         .getElementById("fullcontainer")
      //         .scrollIntoView({ block: "end" });
      //     }
      //   }, 500);
      // }
    });
  }

  savePinnedItem(item, type) {
    const data = {
      conversation_id: this.conversation_id,
      product_sku: item.product_id,
      action: type,
    };
    this.api.savePinBoard(data).subscribe((res) => {
      this.getPinBoardList();
    });
  }

  getPinBoardList() {
    this.chatService
      .getPinboardList(this.conversation_id)
      .subscribe((res: any) => {
        if (res.status) {
          this.pinBoardList = res.products;
        }
      });
  }
  get viewBtnWidth() {
    if (document.getElementById("plp")) {
      return document.getElementById("plp").offsetWidth;
    } else {
      return 0;
    }
  }

  get imgSectionWidth() {
    if (document.getElementById("mainImageDesk")) {
      return document.getElementById("mainImageDesk").offsetWidth;
    } else {
      return 0;
    }
  }

  loadMore(fromBtn = false) {
    this.lastScrollPosition = 1166; // document.getElementById('fullcontainer').offsetHeight;

    this.chat.emit({
      fromBtn: fromBtn,
      sub_product: this.chatData.sub_product ? this.chatData.sub_product : [],
      message: "show me more recommended products",
    });
    this.loaded = false;
  }

  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);
  }

  back() {
    console.log(this.deviceWidth);
    let d: HTMLElement = document.getElementById(
      "productContainer"
    ) as HTMLElement;
    d.scrollTo({
      left: d.scrollLeft - 1200,
      top: 0,
      behavior: "smooth",
    });
  }

  next() {
    let d: HTMLElement = document.getElementById(
      "productContainer"
    ) as HTMLElement;
    d.scrollTo({
      left: d.scrollLeft + d.offsetWidth,
      top: 0,
      behavior: "smooth",
    });
  }

  navigateToProducts(p) {
    this.router.navigate([]).then(() => {
      window.open(`/product/${p.product_id}`);
    });
  }

  navigateOrAddToCart(p, event) {
    this.navigateToProducts(p);
    // if (
    //   event.target.classList.value.includes("mtb-a") ||
    //   event.target.classList.value.includes("btn-cart")
    // ) {
    //   this.addToCart(p);
    // } else {
    //   this.navigateToProducts(p);
    // }
  }

  get scrollLeft() {
    let d = document.getElementById("productContainer") as HTMLElement;
    if (d) {
      return d.scrollLeft > 0;
    }
  }

  get scrollRight() {
    let d = document.getElementById("productContainer") as HTMLElement;
    if (d) {
      return +d.scrollLeft.toFixed(0) >= d.scrollWidth - d.clientWidth;
    }
  }

  addToCart(p) {
    const postData = {
      product_sku: p.sku,
      count: 1,
      parent_sku: p.sku,
      isServiceRepOrder: 0,
      is_virtual: 0,
    };
    this.api.addCartProduct(postData).subscribe(
      (payload: any) => {
        if (payload.status) {
          this.errorMessage = "";

          // this.matDialogUtils.openAddToCartDialog(data);
          this.utils.updateLocalCart(1);
          this.eventEmitterService.updateCartIcon();
          this.snackbar.open("Product added to cart", "x", { duration: 4000 });
        } else {
          this.errorMessage = payload.msg;
        }
      },
      (error: any) => {
        this.errorMessage = "Cannot add this product at the moment.";
      }
    );
  }

  openSignupDialog() {
    this.matDialogUtils.openSignupDialog(this.isHandset);
  }

  openSignInDialog() {
    this.matDialogUtils.openSigninDialog(this.isHandset ? "90%" : "35%");
  }

  openDialog(callAPI = true) {
    this.dialog.open(this.signupDialog, {
      panelClass: "sign-dialog-container",
    });
    this.isAPICall = callAPI;
  }

  checkEmail() {
    if (this.email) {
      this.btnLoader = true;
      this.api.getUserByMail(this.email).subscribe(
        (res) => {
          this.page = 2;
          if (res.status) {
            this.emailAvailable = true;
          } else {
            this.emailAvailable = false;
          }
          this.btnLoader = false;
        },
        (err) => {
          this.btnLoader = false;
        }
      );
    }
  }
  email = "";
  password = "";
  signup() {
    if (this.validateForm(this.email, this.password)) {
      this.btnLoader = true;

      const formData: any = new FormData();
      formData.append("name", this.email);
      formData.append("email", this.email);
      formData.append("password", this.password);
      formData.append("c_password", this.password);
      this.api.signup(formData).subscribe(
        (payload: any) => {
          if (payload.success) {
            this.error = false;
            this.eventService.fetchUser(
              payload.success.token,
              payload.success.user
            );
            this.btnLoader = false;
            setTimeout(() => {
              this.callSuccessAPI();
            }, 1000);

            this.close();
          }
        },
        (error: any) => {
          this.btnLoader = false;
          if (error.error.error.email) {
            this.error = true;

            this.errorMsg = "This email already exists";
            return false;
          }
        }
      );
    }
  }
  handleError(payload: any) {
    this.error = true;
    this.btnLoader = false;
    this.errorMsg = payload.error;
  }

  signIn() {
    if (this.validateForm(this.email, this.password)) {
      this.btnLoader = true;
      const formData: any = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);
      this.api.login(formData).subscribe(
        (payload: any) => {
          if (payload.success) {
            this.eventService.fetchUser(payload.success.token, payload.user);
            this.error = false;
            this.btnLoader = false;

            setTimeout(() => {
              this.callSuccessAPI();
            }, 1000);
            this.close();
            this.api.dialodClosed$.next(true);
          } else {
            this.handleError(payload);
          }
        },
        (payload: any) => this.handleError(payload.error)
      );
    }
  }
  socialSignIn(platform) {
    let socialPlatformProvider;

    socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;

    this.socialAuthService.signIn(socialPlatformProvider).then((userData) => {
      this.api
        .getAuthToken(userData.authToken, platform)
        .subscribe((payload: any) => {
          // this.snakBar.open("User registered successfully", "x", {
          //   duration: 2000,
          // });
          // this.cookie.set("token", `${payload.success.token}`, new Date(), "/");
          this.email = payload.user.email;
          localStorage.setItem("user", JSON.stringify(payload.user));
          this.eventService.fetchUser(payload.success.token, payload.user);
          setTimeout(() => {
            this.callSuccessAPI();
          }, 1000);

          // this.updateUser(payload);
          this.close();
        });
    });
  }
  callSuccessAPI() {
    if (this.isAPICall) {
      this.api.aiSubscribe(this.email).subscribe(() => {
        this.api.createPromo().subscribe(() => {
          this.email = "";
        });
      });
    }
  }
  validateForm(email, password) {
    this.error = false;
    if (!email) {
      this.error = true;
      this.errorMsg = "Email cannot be blank";
    } else if (!password) {
      this.error = true;
      this.errorMsg = "Password cannot be blank";
    } else if (password.length < 8) {
      this.error = true;
      this.errorMsg = "Password must contain 8 characters";
    }
    return !this.error;
  }
  close() {
    this.page = 1;
    this.dialog.closeAll();
    this.error = false;
    this.errorMessage = "";
  }
}
type SubCategory = {
  sub_category: string | null;
  displayable: number;
  category_id: string | null;
};

type Category = {
  category_url: string;
  category_name: string;
  displayable: number;
  image: string;
  sub_categories: SubCategory[];
};

type Department = {
  department: string;
  dept_url: string;
  displayable: number;
  link: string;
  image: string;
  categories: Category[];
};

type LivingRoom = {
  class: string;
  nav_group: number;
  class_url: string;
  class_image: string;
  departments: Department[];
};
