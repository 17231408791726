import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from "@angular/cdk/layout";
import { Location } from "@angular/common";
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { ApiService } from "src/app/shared/services";
@Component({
  selector: "app-view-project-intake-form",
  templateUrl: "./view-project-intake-form.component.html",
  styleUrls: ["./view-project-intake-form.component.less"],
})
export class ViewProjectIntakeFormComponent implements OnInit, AfterViewInit,OnDestroy {
  @Input() data: any;
  @Input() projectData: any;
  @Input() isHandset: boolean;
  buttonSelected = 0;
  bpSubscription: Subscription;
  bpObserver: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.Handset
  );
  styleData:any;
  deviceHeight: number;
  projectImages: any;
  constructor(
    private api: ApiService,
    private actRou: ActivatedRoute,
    private router: Router,
    private location: Location,
    private breakpointObserver: BreakpointObserver
  ) {}
  ngOnDestroy(): void {
    this.api.onInitLoaded = false;
  }
  
  reloadPage(){
    this.api.onInitLoaded = false;
    this.ngOnInit();
  }
  get innerWidth():number{
    return window.innerWidth
  }
  ngOnInit(): void {
    this.deviceHeight = window.innerHeight
    if (!this.api.onInitLoaded) {
      this.bpSubscription = this.bpObserver.subscribe(
        (handset: BreakpointState) => {
          this.isHandset = handset.matches;
        }
      );
      this.api.projectId = this.actRou.snapshot.params.id;
  
      if (this.location.path().includes("view-project-intake")) {
        this.buttonSelected = 1;
      } else if (this.location.path().includes("view-client-files")) {
        this.buttonSelected = 2;
      }
      this.api.getAllAppointments("", this.api.projectId).subscribe((res) => {
        if (res && res.length > 0) {
          this.projectData = res[0];
          this.projectData.tasks_summary = true;
          this.projectData.tasks_vision = true;
          this.projectData.tasks_upload = true;
          this.getStyleReport();
        }
      });
      this.getDesignImage();
      this.getDesignIntake();
      this.api.onInitLoaded = true;
    }
  }
  getStyleReport(){
    this.api.getStyleReport(this.projectData.style_quiz_id).subscribe((res:any)=>{
      console.log(res,'re')
      this.styleData = res.genome[0];
    })
  }
  getDesignIntake() {
    this.api.getDesignIntake(this.api.projectId).subscribe((resp: any) => {
      if (resp && resp.length > 0) {
        this.data = resp[0];
      }
    });
  }

  getDesignImage(){
    this.api.getDesignImages(this.api.projectId).subscribe((resp: any) => {
      if (resp && resp.length > 0) {
        this.projectImages = resp;
      }
    });
  }
  buttonClick(c) {
    this.buttonSelected = c;
    if (c === 1) {
      this.location.replaceState(
        `design/projects/${this.api.projectId}/view-project-intake`
      );
    } else if (c == 2) {
      this.location.replaceState(
        `design/projects/${this.api.projectId}/view-client-files`
      );
    }
  }
  openReport(d) {
    this.router.navigate([]).then(() => {
      window.open(`/style-quiz/my-style/${d.style_quiz_id}`);
    });
  }
  showEditPage() {
    this.router.navigate([`/admin/dashboard/design/bookings`]);
  }
  ngAfterViewInit(): void {
    this.hideIntercomIcon()
  }
  hideIntercomIcon() {
    const interval = setInterval(() => {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app"
      )[0];
      if (intercom) {
        intercom.classList.add("dn");
        clearInterval(interval);
      }
    }, 500);
    const intervalx = setInterval(() => {
      const intercomx = document.getElementsByClassName("intercom-app")[0];
      if (intercomx) {
        intercomx.classList.add("dn");
        clearInterval(intervalx);
      }
    }, 500);
  }
}