import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    AllProductsModule,
    CartModule,
    CategoriesModule,
    ForgotPasswordModule,
    LandingModule,
    ProductsModule,
    ResetPasswordComponent,
    ResetPasswordModule,
    SearchModule,
    WishlistModule
} from './containers';
import {
    EditMyProfileModule,
    OrderSuccessModule,
    PaymentModule,
    ProductDetailsMobileModule,
    PublicProfileModule,
    ReviewFormMobileModule
} from './components';
import { BrandListModule } from './components/brand-list/brand-list.module';
import { ProductCollectionsComponent } from './containers/product-collections/product-collections.component';
import { CollectionListModule } from './components/collection-list/collection-list.module';
import { OfferDailogComponent } from './components/offer-dailog/offer-dailog.component';
import { NewsletterPopupComponent } from './components/newsletter-popup/newsletter-popup.component';
import {
    CustomSnackBarComponent,
    WishlistLoginPromptModalComponent
} from './components/modals/wishlist-login-prompt-modal/wishlist-login-prompt-modal.component';
import { SharedModule } from '../shared/shared.module';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { PrdAvailStatusDialogComponent } from './components/prd-avail-status-dialog/prd-avail-status-dialog.component';
import { QuizDialogComponent } from './containers/quiz-dialog/quiz-dialog.component';
import { ProductKeywordMappingModule } from './containers/product-keyword-mapping/product-keyword-mapping.module';
import { ProductPreviewComponent } from './components/product-preview/product-preview.component';
import { DesignDashboardComponent } from './containers/design-dashboard/design-dashboard.component';
import { ProductAllComponent } from './components/product-all/product-all.component';
import { BrandsComponent } from './components/brands/brands.component';
import { ViewFilesComponent } from './components/view-files/view-files.component';
import { NgxPaginationModule } from 'ngx-pagination'; 
import { AuthModule } from '../core/components/auth/auth.module';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { ProductPreviewMobileComponent } from './components/product-details/product-preview-mobile/product-preview-mobile.component';
import { ViewProjectIntakeFormComponent } from './components/view-project-intake-form/view-project-intake-form.component';

const DECLARATIONS = [
    ResetPasswordComponent,
    ProductCollectionsComponent,
    OfferDailogComponent
];
const MODULES = [
    CommonModule,
    ProductsModule,
    LandingModule,
    SearchModule,
    WishlistModule,
    AllProductsModule,
    CategoriesModule,
    ProductDetailsMobileModule,
    CartModule,
    PaymentModule,
    OrderSuccessModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    EditMyProfileModule,
    BrandListModule,
    PublicProfileModule,
    CollectionListModule,
    SharedModule,
    NgxPaginationModule,
    // ReviewFormModule,
    ReviewFormMobileModule,
    ProductKeywordMappingModule,
    AuthModule
];

@NgModule({
    declarations: [
        ...DECLARATIONS,
        NewsletterPopupComponent,
        WishlistLoginPromptModalComponent,
        CustomSnackBarComponent,
        AdminDashboardComponent,
        PrdAvailStatusDialogComponent,
        QuizDialogComponent,
        ProductPreviewComponent,
        DesignDashboardComponent,
        ProductAllComponent,
        BrandsComponent,
        ViewFilesComponent,
        ThankYouComponent,
        ProductPreviewMobileComponent,
        ViewProjectIntakeFormComponent
        // ProductStatusIsdropComponent,
    ],
    imports: [...MODULES],
    // providers: [AmericanNumberPipe],
    exports: [...DECLARATIONS, ...MODULES],
    entryComponents: [OfferDailogComponent, NewsletterPopupComponent, WishlistLoginPromptModalComponent, CustomSnackBarComponent,PrdAvailStatusDialogComponent,QuizDialogComponent]
})
export class FeatureModule {
}